import React, {useContext} from 'react';

import {GlobalStateContext} from '../context/GlobalThemeContextProvider';
import Layout from '../components/layout';
import SEO from '../components/seo';
import Container from '../components/container';
import TopNews from '../components/top-news';
import OneImgSlider from '../components/one-img-slider';
import NewsSlider from '../components/news-slider';
import NewsList from '../components/news-list';
import HomeForm from '../components/home-form';
import classes from './index.module.css';

const IndexPage: React.FC<never> = () => {
  const {theme} = useContext(GlobalStateContext);

  return (
    <div className={classes.root}>
      <Layout>
        <SEO title="Головна" />
        {theme == 'regular' && (
          <Container className={classes.top}>
            <TopNews />
            <OneImgSlider />
          </Container>
        )}
        {theme == 'regular' ? <NewsSlider /> : <NewsList />}
        <HomeForm />
      </Layout>
    </div>
  );
};

export default IndexPage;
