import React, {useContext} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {navigate} from 'gatsby';
import {ThemeProvider, createMuiTheme} from '@material-ui/core/styles';
import clsx from 'clsx';

import {GlobalStateContext} from '../context/GlobalThemeContextProvider';
import TextInput from './text-input';
import SubmitButton from './submit-button';
import classes from './home-form.module.css';

const HomeForm: React.FC = () => {
  const {theme} = useContext(GlobalStateContext);

  const MuiTheme = createMuiTheme({
    palette: {
      primary: {
        main: theme == 'regular' ? '#fdc30a' : theme == 'blackOnWhite' ? '#000' : '#fff',
      },
      secondary: {
        main: theme == 'regular' ? '#fdc30a' : theme == 'blackOnWhite' ? '#000' : '#fff',
      },
    },
  });

  const {handleSubmit, handleChange, handleBlur, values, touched, errors} = useFormik({
    initialValues: {
      name: '',
      email: '',
      question: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Обов'язкове поле"),
      email: Yup.string().email('Невірна адреса електронної пошти').required("Обов'язкове поле"),
      question: Yup.string().required("Обов'язкове поле"),
    }),
    onSubmit: (values, {setSubmitting, resetForm, setStatus}) => {
      let formData = new FormData();
      formData.append('name', values.name);
      formData.append('email', values.email);
      formData.append('question', values.question);

      fetch(process.env.APPS_SCRIPT_URL_FOR_INDEX_FORM!, {method: 'POST', body: formData})
        .then(res =>
          navigate('/submit-info/', {
            state: {title: res.ok === true ? 'Запит відправлено успішно' : 'Не вдалося відправити запит'},
          }),
        )
        .catch(err => navigate('/submit-info/', {state: {title: 'Виникла помилка, спробуйте пізніше'}}));
    },
  });

  return (
    <section className={clsx(theme == 'regular' && classes.root)}>
      <div
        className={clsx(
          classes.question,
          theme == 'regular' ? classes.before_and_after : classes.question_black_and_white,
        )}
      >
        <h2 className={clsx(classes.question_title, theme !== 'regular' && classes.question_title_black_and_white)}>
          Виникли питання? Запитуйте!
        </h2>
        <form className={classes.question_form} onSubmit={handleSubmit}>
          <ThemeProvider theme={MuiTheme}>
            <TextInput
              name="name"
              type="text"
              label="Ваше ім’я"
              value={values.name}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.name}
              error={errors.name}
            />
            <TextInput
              name="email"
              type="email"
              label="Ваша електронна пошта"
              value={values.email}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.email}
              error={errors.email}
            />
            <TextInput
              name="question"
              type="text"
              label="Ваше питання"
              value={values.question}
              handleChange={handleChange}
              handleBlur={handleBlur}
              touched={touched.question}
              error={errors.question}
              multiline={true}
            />
            <SubmitButton />
          </ThemeProvider>
        </form>
      </div>
    </section>
  );
};

export default HomeForm;
